import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { useDispatch, useSelector } from "react-redux";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

import {
  getApplications,
  getDistrictsFromIDP,
  getDistrictUsersFromIDP,
} from "../actions/Applications/Applications";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 250,
  },
}));

export default function AssumeUser() {
  const user = useSelector((state) => state.user.user);
  const applications = useSelector((state) => state.applications.applications);
  const districts = useSelector((state) => state.districts.districts);
  const districtUsers = useSelector((state) => state.districts.users);
  const dispatch = useDispatch();
  useEffect(() => {
    getApplications(user, dispatch);
  }, []);
  const classes = useStyles();
  const [application, setApplication] = useState("");
  const [assumeUserUserUrl, setAssumeUserUserUrl] = useState("");

  const [url, setUrl] = React.useState({
    location: null,
  });

  const [district, setDistrict] = React.useState({
    districtID: null,
  });

  const [userInfo, setUserInfo] = React.useState({
    userInfo: {},
  });

  const isStwk = application.toLowerCase().includes("strategy workshop");
  const handleChangeApplication = (event, newValue) => {
    setApplication(newValue.applicationName);
    setAssumeUserUserUrl(newValue.applicationAssumeUrlUser);
    getDistrictsFromIDP({
      user,
      dispatch,
      assumeUserDistrictUrl: newValue.applicationAssumeUrlDistrict,
    });
    setUrl({
      location: newValue.tokenIntakeURL,
    });
  };
  const handleChangeDistrict = (event, newValue) => {
    setDistrict({
      districtID: newValue.district_id,
    });

    getDistrictUsersFromIDP({
      user,
      district_id: newValue.district_id,
      dispatch,
      assumeUserUserUrl,
    });
  };
  const handleChangeUser = (event, newValue) => {
    setUserInfo({
      ...newValue,
      email: newValue.email,
    });
  };

  const encodeUserObject = (userInput) => {
    console.log(userInput, "Dylan");
    let user_temp = {
      eaUserAssumingATeacher: true,
      eaEmail: user.email,
      districtID: district.districtID,
      eaAdmin: false,
      email: userInput.email,
      idpAdmin: false,
      ssoUser: true,
      token: user.token,
    };
    return btoa(JSON.stringify(user_temp));
  };

  return (
    <Grid
      container
      alignItems={"flex-start"}
      justifyContent={"center"}
      className={classes.root}
      style={{ marginTop: "5em" }}
    >
      <Grid item xs={12}>
        <Grid container justifyContent="center" spacing={2}>
          <FormControl className={classes.formControl}>
            <Autocomplete
              id="combo-box-demo"
              options={applications.sort((a, b) =>
                a?.applicationName?.localeCompare(b?.applicationName)
              )}
              getOptionLabel={(option) => option.applicationName}
              style={{ width: 300 }}
              onChange={(event, newValue) => {
                if (newValue) {
                  handleChangeApplication(event, newValue);
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Applications"
                  variant="outlined"
                />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Grid container justifyContent="center" spacing={2}>
            <FormControl className={classes.formControl}>
              <Autocomplete
                id="combo-box-demo"
                options={districts}
                getOptionLabel={(option) => {
                  return option.district_name;
                }}
                style={{ width: 300 }}
                onChange={(event, newValue) => {
                  if (newValue) {
                    handleChangeDistrict(event, newValue);
                  }
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Districts" variant="outlined" />
                )}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid container justifyContent="center" spacing={2}>
          <FormControl className={classes.formControl}>
            <Autocomplete
              disabled={!districtUsers.init}
              id="combo-box-demo"
              options={districtUsers.list}
              getOptionLabel={(option) => {
                if (isStwk) {
                  return option.firstName + " " + option.lastSurname;
                } else {
                  return `${option.staff_given_name} ${option.staff_family_name} (${option.staff_role})`;
                }
              }}
              style={{ width: 300 }}
              onChange={(event, newValue) => {
                if (newValue) {
                  handleChangeUser(event, newValue);
                }
              }}
              renderInput={(params) => (
                <TextField {...params} label="Users" variant="outlined" />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Grid container justifyContent="center" spacing={2}>
            <FormControl className={classes.formControl}>
              <Button
                style={{ width: 300, height: "4em" }}
                variant="contained"
                color="primary"
                onClick={() => {
                  window.open(
                    `${url.location}${encodeUserObject(userInfo)}`,
                    "_blank"
                  );
                }}
              >
                Launch
              </Button>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
