import React, { useEffect, useState } from "react";
import Container from "@material-ui/core/Container/Container";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import AddIcon from "@material-ui/icons/Add";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import axios from "axios";
import { toast } from "react-toastify";
import Paper from "@material-ui/core/Paper";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Collapse from "@material-ui/core/Collapse";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import clsx from "clsx";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import GetAppIcon from "@material-ui/icons/GetApp";

import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import history from "../history";

const API = process.env.REACT_APP_API;

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: "1em",
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  paper2: {
    paddingLeft: "2em",
    paddingRight: "2em",
    paddingTop: "1em",
    paddingBottom: "1em",
    marginTop: "1em",
  },
  wrapText: {
    whiteSpace: "normal",
    overflowWrap: "anywhere",
  },
}));

function getSteps() {
  return ["IDP Settings", "Create The Connection"];
}

export default function CreateSSOConnection() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const user = useSelector((state) => state.user.user);
  const steps = getSteps();

  const [expanded, setExpanded] = React.useState(false);
  const [expanded1, setExpanded1] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  const handleExpandClick1 = () => {
    setExpanded1(!expanded1);
  };
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const [activeStep, setActiveStep] = useState(0);
  const [spSettings, setSpSettings] = useState({
    uuid: "",
    metaDataRoute: "",
    acsRoute: "",
    ssoRoute: "",
    sloRoute: "",
    sp_x509: "",
    createdIDPInit: false,
  });

  const createValidatedURL = (url) => {
    let returnString = "^";
    const escapedCharacters = ["/", "."];
    for (let i = 0; i < url.length; i++) {
      if (escapedCharacters.includes(url[i])) {
        returnString += "\\";
      }
      returnString += url[i];
    }
    returnString += "$";
    return returnString;
  };
  let newIDPSettings = {
    idp_district_id: "",
    idp_entityId: "",
    idp_singleSignOnService_url: "",
    idp_singleLogoutService_url: "",
    idp_x509cert: "",
    idp_connection_name: "",
    email_domains: [],
  };

  const [idpSettings, setIdpSettings] = useState(newIDPSettings);
  function copyCodeToClipboard(text) {
    const textField = document.createElement("textarea");
    textField.innerText = text;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();

    toast.success(`Value copied!`, {
      position: "top-right",
      autoClose: 1500,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  }
  const handleClose = (setDefault = false) => {
    if (setDefault) {
      const headers = {
        AUTHORIZATION: user.token,
      };
      axios
        .post(
          `${API}/auth/default_connection`,
          {
            district_id: user.districtID,
            default_connection_type: "SAML",
            email: user.email,
            connection_id: spSettings.uuid,
          },
          {
            headers: headers,
          }
        )
        .then((data) => {
          console.log(data);
          toast.success("Saved as active connection.", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        })
        .catch((err) => {
          toast.success("Error saving as active connection.", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
          console.log(err);
        });
    }

    setOpen(false);
  };

  useEffect(() => {
    toast.info("Gathering SP settings.", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
    //Get the new idp connection ID to create routes.
    try {
      const headers = {
        AUTHORIZATION: user.token,
      };
      const districtID = user.districtID;
      axios
        .post(
          API + "/saml/init_idp",
          {
            districtID: districtID,
            email: user.email,
          },
          {
            headers: headers,
          }
        )
        .then((res) => {
          const {
            createdIDPInit,
            idp_district_id,
            idp_entityId,
            idp_singleLogoutService_url,
            idp_singleSignOnService_url,
            idp_x509cert,
            uuid,
            sp_x509,
            idp_connection_name,
            domains,
          } = res.data.return_items;

          if (createdIDPInit !== "") {
            setSpSettings((prevState) => {
              return {
                ...prevState,
                createdIDPInit: createdIDPInit,
              };
            });
          }

          if (idp_district_id !== "") {
            setIdpSettings((prevState) => {
              return {
                ...prevState,
                idp_district_id: idp_district_id,
              };
            });
          }

          if (idp_entityId !== "") {
            setIdpSettings((prevState) => {
              return {
                ...prevState,
                idp_entityId: idp_entityId,
              };
            });
          }

          if (idp_singleLogoutService_url !== "") {
            setIdpSettings((prevState) => {
              return {
                ...prevState,
                idp_singleLogoutService_url: idp_singleLogoutService_url,
              };
            });
          }

          if (idp_connection_name !== "") {
            newIDPSettings.idp_connection_name = idp_connection_name;
            setIdpSettings((prevState) => {
              return {
                ...prevState,
                idp_connection_name: idp_connection_name,
              };
            });
          }

          if (idp_singleSignOnService_url !== "") {
            setIdpSettings((prevState) => {
              return {
                ...prevState,
                idp_singleSignOnService_url: idp_singleSignOnService_url,
              };
            });
          }

          if (idp_x509cert !== "") {
            setIdpSettings((prevState) => {
              return {
                ...prevState,
                idp_x509cert: idp_x509cert,
              };
            });
          }
          if (domains.length !== 0) {
            setIdpSettings((prevState) => {
              return {
                ...prevState,
                email_domains: domains.join(", "),
              };
            });
          }

          if (uuid !== "") {
            setSpSettings((prevState) => {
              return {
                ...prevState,
                uuid: uuid,
              };
            });
          }

          if (sp_x509 !== "") {
            setSpSettings((prevState) => {
              return {
                ...prevState,
                sp_x509: sp_x509,
              };
            });
          }
          toast.success("SP + IDP settings fetched!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        });
    } catch (error) {
      console.log(error);
    }
  }, []);

  function submitIdpSettings(setAsDefault = false) {
    console.log("SET AS ACTIVE");
    toast.info("Submitting SP Settings", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
    try {
      const headers = {
        AUTHORIZATION: user.token,
      };
      const districtID = user.districtID;
      axios
        .post(
          API + "/saml/create_connection",
          {
            districtID: districtID,
            idp_district_id: idpSettings.idp_district_id,
            idp_entityId: idpSettings.idp_entityId,
            idp_singleSignOnService_url:
              idpSettings.idp_singleSignOnService_url,
            idp_singleLogoutService_url:
              idpSettings.idp_singleLogoutService_url,
            idp_x509cert: idpSettings.idp_x509cert,
            uuid: spSettings.uuid,
            idp_connection_name: idpSettings.idp_connection_name,
            email: user.email,
            email_domains: [...idpSettings.email_domains.split(",")],
          },
          {
            headers: headers,
          }
        )
        .then((res) => {
          toast.success("Connection Saved", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
          handleNext();
          if (setAsDefault) {
            setOpen(true);
          }
        })
        .catch((error) => {
          toast.error(
            `Error saving connection: ${error},  ${error.response.data.msg}`,
            {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            }
          );
        });
    } catch (error) {
      console.log(error);
    }
  }

  function getStepContent(stepIndex) {
    switch (stepIndex) {
      case 4:
        return (
          <div className={classes.paper}>
            <Paper className={classes.paper2} elevation={3}>
              <Typography component="h1" variant="h5">
                SP MetaData Route
              </Typography>
              <Typography component="h5" variant="p">
                {API}/saml/metadata/{spSettings.uuid}
              </Typography>
            </Paper>
            <Paper className={classes.paper2} elevation={3}>
              <Typography component="h1" variant="h5">
                SP ACS Route
              </Typography>
              <Typography component="h5" variant="p">
                {API}/saml/acs/{spSettings.uuid}
              </Typography>
            </Paper>
            <Paper className={classes.paper2} elevation={3}>
              <Typography component="h1" variant="h5">
                SP SSO Route
              </Typography>
              <Typography component="h5" variant="p">
                {API}/saml/login/{spSettings.uuid}
              </Typography>
            </Paper>
            <Paper className={classes.paper2} elevation={3}>
              <Typography component="h1" variant="h5">
                SP SLO Route
              </Typography>
              <Typography component="h5" variant="p">
                {API}/saml/slo/{spSettings.uuid}
              </Typography>
            </Paper>
            <Paper className={classes.paper2} elevation={3}>
              <Typography component="h1" variant="h5">
                SP X509 Cert
              </Typography>
              <p className={classes.wrapText}>{spSettings.sp_x509}</p>
            </Paper>
          </div>
        );
      case 0:
        return (
          <div className={classes.paper}>
            <Typography component="h1" variant="h5">
              New SSO Connection <AddIcon />
            </Typography>
            <Typography component="h4" variant="h6">
              Enter the values provided by your IDP
            </Typography>
            <form
              className={classes.form}
              noValidate
              autoComplete={false}
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <TextField
                    name="idp_connection_name"
                    variant="outlined"
                    required
                    fullWidth
                    id="idp_connection_name"
                    label="District name *This will display for users to select"
                    autoFocus
                    defaultValue={idpSettings.idp_connection_name || ""}
                    value={idpSettings.idp_connection_name || ""}
                    onChange={(e) => {
                      let new_value = e.target.value;
                      setIdpSettings((prevState) => {
                        return {
                          ...prevState,
                          idp_connection_name: new_value,
                        };
                      });
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    name="idp_entityId"
                    variant="outlined"
                    required
                    fullWidth
                    id="idp_entityId"
                    label="IDP Entity ID"
                    autoFocus
                    value={idpSettings.idp_entityId || ""}
                    onChange={(e) => {
                      let new_value = e.target.value;
                      setIdpSettings((prevState) => {
                        return {
                          ...prevState,
                          idp_entityId: new_value,
                        };
                      });
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    name="idp_singleSignOnService_url"
                    variant="outlined"
                    required
                    fullWidth
                    id="idp_singleSignOnService_url"
                    label="IDP Single Sign On URL"
                    autoFocus
                    defaultValue={idpSettings.idp_singleSignOnService_url || ""}
                    value={idpSettings.idp_singleSignOnService_url || ""}
                    onChange={(e) => {
                      let new_value = e.target.value;
                      setIdpSettings((prevState) => {
                        return {
                          ...prevState,
                          idp_singleSignOnService_url: new_value,
                        };
                      });
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    name="idp_singleLogoutService_url"
                    variant="outlined"
                    fullWidth
                    required
                    id="idp_singleLogoutService_url"
                    label="IDP Single Log Out URL"
                    autoFocus
                    defaultValue={idpSettings.idp_singleLogoutService_url || ""}
                    value={idpSettings.idp_singleLogoutService_url || ""}
                    onChange={(e) => {
                      let new_value = e.target.value;
                      setIdpSettings((prevState) => {
                        return {
                          ...prevState,
                          idp_singleLogoutService_url: new_value,
                        };
                      });
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    fullWidth
                    name="idp_x509cert"
                    id="idp_x509cert"
                    label="IDP x509 Certficate"
                    multiline
                    rows={10}
                    autoFocus
                    variant="outlined"
                    defaultValue={idpSettings.idp_x509cert || ""}
                    onChange={(e) => {
                      let new_value = e.target.value;
                      setIdpSettings((prevState) => {
                        return {
                          ...prevState,
                          idp_x509cert: new_value,
                        };
                      });
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    fullWidth
                    name="email_domains"
                    id="email_domains"
                    label="Email Domains (Comma Separated)"
                    autoFocus
                    variant="outlined"
                    value={idpSettings?.email_domains || ""}
                    onChange={(e) => {
                      let new_value = e.target.value;
                      setIdpSettings((prevState) => {
                        return {
                          ...prevState,
                          email_domains: new_value,
                        };
                      });
                    }}
                  />
                </Grid>
              </Grid>
            </form>
          </div>
        );
      case 1:
        return (
          <div>
            <div className={classes.paper}>
              <Card className={classes.root}>
                <CardContent>
                  <Typography variant="h6" color="textPrimary" component="p">
                    Enter these values into your IDP
                    <Typography>
                      Click to download metadata XML
                      <Tooltip title="Click to copy value to clipboard">
                        <IconButton aria-label="delete">
                          <GetAppIcon
                            onClick={(e) => {
                              window.open(
                                `${API}/saml/metadata/download/${spSettings.uuid}`,
                                "_blank"
                              );
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                    </Typography>
                  </Typography>
                </CardContent>
                <CardContent>
                  <Paper className={classes.paper2} elevation={3}>
                    <Typography component="h1" variant="h5">
                      SP EntityID
                      <Tooltip title="Click to copy value to clipboard">
                        <IconButton aria-label="delete">
                          <FileCopyIcon
                            onClick={(e) => {
                              copyCodeToClipboard(`${API}/saml/metadata`);
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                    </Typography>
                    <Typography component="h5" variant="p">
                      {API}/saml/metadata
                    </Typography>
                  </Paper>
                  <Paper className={classes.paper2} elevation={3}>
                    <Typography component="h1" variant="h5">
                      SP Metadata URL
                      <Tooltip title="Click to copy value to clipboard">
                        <IconButton aria-label="delete">
                          <FileCopyIcon
                            onClick={(e) => {
                              copyCodeToClipboard(
                                `${API}/saml/metadata/${spSettings.uuid}`
                              );
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                    </Typography>
                    <Typography component="h5" variant="p">
                      {API}/saml/metadata/{spSettings.uuid}
                    </Typography>
                  </Paper>
                  <Paper className={classes.paper2} elevation={3}>
                    <Typography component="h1" variant="h5">
                      SP ACS URL
                      <Tooltip title="Click to copy value to clipboard">
                        <IconButton aria-label="delete">
                          <FileCopyIcon
                            onClick={(e) => {
                              copyCodeToClipboard(
                                `${API}/saml/acs/${spSettings.uuid}`
                              );
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                    </Typography>
                    <Typography component="h5" variant="p">
                      {API}/saml/acs/{spSettings.uuid}
                    </Typography>
                  </Paper>
                  <Paper className={classes.paper2} elevation={3}>
                    <Typography component="h1" variant="h5">
                      SP SSO URL
                      <Tooltip title="Click to copy value to clipboard">
                        <IconButton aria-label="delete">
                          <FileCopyIcon
                            onClick={(e) => {
                              copyCodeToClipboard(
                                `${API}/saml/login/${spSettings.uuid}`
                              );
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                    </Typography>
                    <Typography component="h5" variant="p">
                      {API}/saml/login/{spSettings.uuid}
                    </Typography>
                  </Paper>
                  <Paper className={classes.paper2} elevation={3}>
                    <Typography component="h1" variant="h5">
                      SP SLO URL
                      <Tooltip title="Click to copy value to clipboard">
                        <IconButton aria-label="delete">
                          <FileCopyIcon
                            onClick={(e) => {
                              copyCodeToClipboard(
                                `${API}/saml/slo/${spSettings.uuid}`
                              );
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                    </Typography>
                    <Typography component="h5" variant="p">
                      {API}/saml/slo/{spSettings.uuid}
                    </Typography>
                  </Paper>
                  <Paper className={classes.paper2} elevation={3}>
                    <Typography component="h1" variant="h5">
                      SP X509 Cert
                      <Tooltip title="Click to copy value to clipboard">
                        <IconButton aria-label="delete">
                          <FileCopyIcon
                            onClick={(e) => {
                              copyCodeToClipboard(spSettings.sp_x509);
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                    </Typography>
                    <p className={classes.wrapText}>{spSettings.sp_x509}</p>
                  </Paper>
                  <Paper className={classes.paper2} elevation={3}>
                    <Typography component="h1" variant="h5">
                      If your IDP needs a validated URL*
                      <Tooltip title="Click to copy value to clipboard">
                        <IconButton aria-label="delete">
                          <FileCopyIcon
                            onClick={(e) => {
                              copyCodeToClipboard(
                                createValidatedURL(
                                  `${API}/saml/acs/${spSettings.uuid}`
                                )
                              );
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                    </Typography>
                    {createValidatedURL(`${API}/saml/acs/${spSettings.uuid}`)}
                  </Paper>
                </CardContent>
              </Card>
              <Card className={classes.root}>
                <CardContent>
                  <Typography variant="body1" color="textPrimary" component="p">
                    Review IDP settings.
                  </Typography>
                </CardContent>
                <CardActions disableSpacing>
                  <IconButton
                    className={clsx(classes.expand, {
                      [classes.expandOpen]: expanded,
                    })}
                    onClick={handleExpandClick}
                    aria-expanded={expanded}
                    aria-label="show more"
                  >
                    <ExpandMoreIcon />
                  </IconButton>
                </CardActions>
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                  <CardContent>
                    <Paper className={classes.paper2} elevation={3}>
                      <Typography component="h1" variant="h5">
                        District Name
                      </Typography>
                      <Typography component="h5" variant="p">
                        {idpSettings.idp_connection_name}
                      </Typography>
                    </Paper>
                    <Paper className={classes.paper2} elevation={3}>
                      <Typography component="h1" variant="h5">
                        IDP Metadata route
                      </Typography>
                      <Typography component="h5" variant="p">
                        {idpSettings.idp_entityId}
                      </Typography>
                    </Paper>
                    <Paper className={classes.paper2} elevation={3}>
                      <Typography component="h1" variant="h5">
                        IDP SSO URL
                      </Typography>
                      <Typography component="h5" variant="p">
                        {idpSettings.idp_singleSignOnService_url}
                      </Typography>
                    </Paper>
                    <Paper className={classes.paper2} elevation={3}>
                      <Typography component="h1" variant="h5">
                        IDP SLO URL
                      </Typography>
                      <Typography component="h5" variant="p">
                        {idpSettings.idp_singleLogoutService_url}
                      </Typography>
                    </Paper>
                    <Paper className={classes.paper2} elevation={3}>
                      <Typography component="h1" variant="h5">
                        IDP X509 Cert
                      </Typography>
                      <p className={classes.wrapText}>
                        {idpSettings.idp_x509cert}
                      </p>
                    </Paper>
                  </CardContent>
                </Collapse>
              </Card>
            </div>
          </div>
        );
      default:
        return "Unknown stepIndex";
    }
  }

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };
  const redirectToLandingPage = () => {
    history.push("/");
  };
  return (
    <Container component="main" maxWidth="lg">
      <div className={classes.root}>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <div>
          {activeStep === steps.length ? (
            <div>
              <Typography className={classes.instructions}>
                All steps completed
              </Typography>
              <Button onClick={redirectToLandingPage}>Go to launcher</Button>
            </div>
          ) : (
            <div>
              <Typography className={classes.instructions}>
                {getStepContent(activeStep)}
              </Typography>
              <Grid item xs={12} md={12}>
                <ButtonGroup
                  fullWidth
                  aria-label="full width outlined button group"
                >
                  <Button
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    className={classes.backButton}
                  >
                    Back
                  </Button>
                  <Button
                    disabled={!spSettings.createdIDPInit}
                    color="primary"
                    onClick={() => {
                      if (activeStep === 1) {
                        submitIdpSettings();
                      } else {
                        handleNext();
                      }
                    }}
                  >
                    {activeStep === 0 ? "Next" : "Save"}
                  </Button>
                  {activeStep === steps.length - 1 ? (
                    <Button
                      disabled={!spSettings.createdIDPInit}
                      color="primary"
                      onClick={() => {
                        if (activeStep === 1) {
                          submitIdpSettings(true);
                        } else {
                          handleNext();
                        }
                      }}
                    >
                      {activeStep === 1
                        ? "Save and set as active connection"
                        : ""}
                    </Button>
                  ) : (
                    ""
                  )}
                </ButtonGroup>
              </Grid>
            </div>
          )}
        </div>
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Set as active connection?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Setting as the active connection will default traffic through this
            connection.
            <br />
            Disagree will still save the connection for later use.
            <br />
            Agree will save the connection at set it as the active connection.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose(false)} color="primary">
            Disagree
          </Button>
          <Button
            onClick={() => {
              handleClose(true);
            }}
            color="primary"
            autoFocus
          >
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

{
  /*<Container component="main" maxWidth="xs">*/
}

{
  /*</Container>*/
}
